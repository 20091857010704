import React from 'react';
import Layout from '../components/Layout';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Colors from '../components/Colors';
import { faCat } from '@fortawesome/free-solid-svg-icons';
import styled from '@emotion/styled';

const StyledText = styled('p')`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
`;

const BibleText = styled('p')`
  font-size: 2rem;
  text-align: center;
  margin-bottom: 1rem;
  color: ${Colors.main};
  font-weight: bold;
  letter-spacing: 0.3rem;
`;

// Each page will use <> wrapper to get global styles.
export default () => {
  return (
    <>
      <Layout
        jumbo={true}
        pageTitle="Error"
        pageDescription="This is a handy error page. I hope your visit to Christian Bytes remains error free."
      >
        <h1
          css={css`
            color: ${Colors.main} !important;
            font-size: 2rem;
            text-align: center;
            text-transform: uppercase;
            margin-bottom: 2rem;
            border-bottom: 2px solid ${Colors.main};
          `}
        >
          <FontAwesomeIcon
            css={css`
              color: ${Colors.main} !important;
              margin-right: 0.5rem;
            `}
            icon={faCat}
            size={'sm'}
            width="28"
          />
          ERROR
        </h1>
        <StyledText>Looks like you found one of them rare errors!</StyledText>
        <StyledText>
          Maybe click a link on this page and go enjoy one of the fun Christian
          Bytes pages!
        </StyledText>
        <BibleText>Fear not, for I am with you;</BibleText>
        <BibleText>Be not dismayed, for I am your God.</BibleText>
        <BibleText>I will strengthen you,</BibleText>
        <BibleText>Yes, I will help you,</BibleText>
        <BibleText>I will uphold you with My righteous right hand.</BibleText>
        <BibleText>- Isaiah 41:10 (NKJV)</BibleText>
      </Layout>
    </>
  );
};
